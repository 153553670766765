define("discourse/plugins/discourse-onesignal/discourse/templates/onesignal-app-login", ["exports", "@ember/template-factory"], function (_exports, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _default = _exports.default = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="mobile-app-login-intro">
    {{i18n "onesignal.intro"}}
  </div>
  
  */
  {
    "id": "IsL42ocE",
    "block": "[[[10,0],[14,0,\"mobile-app-login-intro\"],[12],[1,\"\\n  \"],[1,[28,[35,1],[\"onesignal.intro\"],null]],[1,\"\\n\"],[13],[1,\"\\n\"]],[],false,[\"div\",\"i18n\"]]",
    "moduleName": "discourse/plugins/discourse-onesignal/discourse/templates/onesignal-app-login.hbs",
    "isStrictMode": false
  });
});